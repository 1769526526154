<template>
  <div class="UserFeedback_I">
    <div class="headNavs">
      <div class="headTilte">用户反馈</div>
      <div class="headDetails">说明：包含问题类型、内容、状态、反馈时间及手机号码</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入问题类型">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect"> 
        <el-table-column prop="feedback_id" label="ID" width="50"  align="center"></el-table-column>
        <el-table-column prop="type" label="问题类型"  align="center"></el-table-column>
        <el-table-column prop="info" label="问题内容"  align="center"></el-table-column> 
        <el-table-column prop="phone" label="手机号码" align="center"></el-table-column>
        <el-table-column prop="is_solve_text" label="状态" align="center"></el-table-column>
        <el-table-column prop="createtime" label="反馈时间"  align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  disabled
                  style="background:#f5f5f5;color:#1890FF"
                  v-if="scope.row.is_solve == 1"
                  type="text"
              >已解决
              </el-button>
           <el-button
                  v-if="scope.row.is_solve == 0"
                  type="text"
                  @click="SolveAdd(scope.row)"
              >确定解决
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>



  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      tableData:[],//总列表
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      //搜索参数
      search: {
        keyword: "",
      },
    }
  },
  mounted() {
    var that = this
    that.loadTable();
  },
  methods: { 
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/general/feedback/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) { 
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search.keyword = ''
      }
      that.loadTable();
    },
    //同意解决
    SolveAdd(data){
      var that = this
      axios({
        method: 'post',
        url: '/admin/general/feedback/finish',
        data: {
          id : data.feedback_id
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    }
  }
}
</script>
 